import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, user) {
      state.status = "success";
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        this._vm.$http
          .post("/auth/login", user)
          .then((resp) => {
            if (resp.data.status == "Success") {
              let data = resp.data.data;
              const user = {
                fullName: data.fullName,
                token: data.token,
                userType: data.userType,
              };
              localStorage.setItem("user", JSON.stringify(user));
              //axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", user);
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("user");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit("logout");
          this._vm.$http.post("/auth/logout").then(() => {
            localStorage.removeItem("user");
          });

          //delete axios.defaults.headers.common["Authorization"];
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  getters: {
    authStatus: (state) => state.status,
    authUser: (state) => state.user,
  },
});
