import Vue from "vue";
import Router from "vue-router";
import user from "@/store/user.js";

// Containers
function TheContainer() {
  return import("@/containers/TheContainer");
}

// Views - Settings
const Profile = () => import("@/views/profile/Index.vue");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");

// Views - Promotion
const Condition = () => import("@/views/condition/Index.vue");
const LuckyCode = () => import("@/views/luckyCode/Index.vue");
const RewardReport = () => import("@/views/rewardReport/Index.vue");
const LuckyProgram = () => import("@/views/luckyProgram/Index.vue");
const Reward = () => import("@/views/reward/Index.vue");

// Views - General
const Shop = () => import("@/views/shop/Index.vue");

// Views - External
const LuckyExternal = () => import("@/views/lucky/Index.vue");

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let authUser = user.getters.authUser;
    if (authUser) {
      if (
        to.matched.every(
          (record) =>
            !record.meta.roles ||
            !record.meta.roles.length ||
            record.meta.roles.includes(authUser.userType)
        )
      ) {
        next();
        return;
      } else {
        next({ name: "Page404" });
      }
    } else {
      let query = to.fullPath ? { returnUrl: to.fullPath } : null;
      next({ name: "Login", query });
    }
  } else {
    next();
  }
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/lucky-program",
      name: "Home",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "reward-report",
          name: "RewardReport",
          component: RewardReport,
        },

        {
          path: "condition",
          name: "Condition",
          component: Condition,
        },

        {
          path: "lucky-code",
          name: "LuckyCode",
          component: LuckyCode,
        },

        {
          path: "lucky-program",
          name: "LuckyProgram",
          component: LuckyProgram,
        },

        {
          path: "reward",
          name: "Reward",
          component: Reward,
        },

        {
          path: "shop",
          name: "Shop",
          component: Shop,
        },

        {
          path: "settings",
          redirect: "/settings/profile",
          name: "Settings",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "profile",
              name: "Profile",
              component: Profile,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
    {
      path: "/lucky/:shopAlias",
      name: "JoiningReward",
      component: LuckyExternal,
    },
  ];
}

export default router;
