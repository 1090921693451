const Contants = {
  MESSAGE: {
    LoginSuccess: "Đăng nhập thành công!",
    LoginInfoIncorrect: "Thông tin đăng nhập không đúng!",
    RequiredUserInfo: "Vui lòng nhập thông tin người dùng!",
    UserNotExist: "Người dùng không tồn tại!",
    LogoutSuccess: "Đăng xuất thành công!",
    NotAuthorization: "Không có quyền thực hiện chức năng này!",
    WannaLogout: "Bạn có muốn đăng xuất?",
    InvalidOrderInfo: "Thông tin đơn hàng chưa đúng. Vui lòng kiểm tra lại!",
    CreateOrderSuccess: "Tạo đơn hàng thành công!",
    UpdateSuccess: "Cập nhật thành công!",
    UpdateError: "Cập nhật không thành công!",
    InvalidData: "Thông tin chưa đúng. Vui lòng kiểm tra lại",
    WrongPassword: "Nhập sai mật khẩu!",
    WrongAmount: "Số tiền chưa đúng",
    PasswordSuccess:
      "Đổi mật khẩu thành công. Hãy đăng nhập lại bằng mật khẩu mới!",
    Duplicated: "Thông tin bị trùng lặp!",
    NotAllowed: "Không cho phép cập nhật!",
    NotExist: "Thông tin không tồn tại!",
  },
  RESPONSE_TYPES: {
    Success: "Success",
    Error: "Error",
  },
  STATUS: {
    Active: 1,
    InActive: 2,
  },
  STATUS_TEXT: {
    1: "Hoạt động",
    2: "Ngưng HĐ",
  },
  ROLES: {
    Admin: 1,
  },
};
Contants.install = function(Vue) {
  Vue.prototype.$const = Contants;
};
export default Contants;
